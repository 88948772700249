import React from 'react'
import './Aside.css'

export const Aside = () => {
  return (
    <>
      <container className="aside-container" id="aside1">
        <div className="profile">
          <div className="nav">
            <ul className="nav-list">
              <li className="nav-item">
                <div className="nav-item__icon">
                  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path clip-rule="evenodd" d="M5.84 15.63a6.97 6.97 0 0 0 8.32 0 8.2 8.2 0 0 0-8.32 0zM4.7 14.57a7 7 0 1 1 10.6 0 9.7 9.7 0 0 0-10.6 0zM10 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17zm-1.5 7a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zm1.5-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" fill="currentColor" fill-rule="evenodd"></path>
                  </svg>
                </div>
                <a href="#profile" className="nav-link">
                  Профиль
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-item__icon">
                  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><g clip-rule="evenodd" fill-rule="evenodd">
                    <path d="M6.25 3.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-1.5 3a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zm-2.06 5.07c.96-.55 2.22-.82 3.56-.82s2.6.27 3.56.82c.98.56 1.69 1.47 1.69 2.68 0 .7-.28 1.3-.78 1.71-.48.39-1.1.54-1.72.54H3.5c-.61 0-1.24-.15-1.72-.54-.5-.4-.78-1-.78-1.71 0-1.21.71-2.12 1.69-2.68zm.75 1.3c-.65.37-.94.84-.94 1.38 0 .3.1.44.22.54.14.11.4.21.78.21H9c.39 0 .64-.1.78-.21.12-.1.22-.25.22-.54 0-.54-.29-1-.94-1.38-.66-.39-1.65-.62-2.81-.62s-2.15.23-2.81.62zM13.75 3.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-1.5 3a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0z"></path></g><path d="M13.75 12.25c-.23 0-.45.01-.68.03a.75.75 0 1 1-.14-1.49c.27-.03.54-.04.82-.04 1.34 0 2.6.27 3.56.82.98.56 1.69 1.47 1.69 2.68 0 .7-.28 1.3-.78 1.71-.48.39-1.1.54-1.72.54h-3a.75.75 0 0 1 0-1.5h3c.39 0 .64-.1.78-.21.12-.1.22-.25.22-.54 0-.54-.29-1-.94-1.38a5.77 5.77 0 0 0-2.81-.62z">

                    </path></g>
                  </svg>
                </div>
                <a href="#subscribers" className="nav-link">
                  Друзья
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-item__icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g id="message_outline_20__Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="message_outline_20__message_outline_20"><path id="message_outline_20__Shape" opacity=".4" d="M0 0h20v20H0z"></path><path d="M6.83 15.75c.2-.23.53-.31.82-.2.81.3 1.7.45 2.6.45 3.77 0 6.75-2.7 6.75-6s-2.98-6-6.75-6S3.5 6.7 3.5 10c0 1.21.4 2.37 1.14 3.35.1.14.16.31.15.49-.04.76-.4 1.78-1.08 3.13 1.48-.11 2.5-.53 3.12-1.22ZM3.24 18.5a1.2 1.2 0 0 1-1.1-1.77A10.77 10.77 0 0 0 3.26 14 7 7 0 0 1 2 10c0-4.17 3.68-7.5 8.25-7.5S18.5 5.83 18.5 10s-3.68 7.5-8.25 7.5c-.92 0-1.81-.13-2.66-.4-1 .89-2.46 1.34-4.35 1.4Z" id="message_outline_20__Icon-Color" fill="currentColor" fill-rule="nonzero"></path></g></g></svg>
                </div>
                <a href="#subscribers" className="nav-link">
                  Сообщения
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-item__icon">
                  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M11.84 2H8.16c-.93 0-1.67 0-2.26.05-.62.05-1.15.15-1.63.4a4.15 4.15 0 0 0-1.82 1.82 4.26 4.26 0 0 0-.4 1.63C2 6.5 2 7.23 2 8.16v3.68c0 .93 0 1.67.05 2.26.05.62.15 1.15.4 1.63.4.78 1.04 1.42 1.82 1.82.48.25 1.01.35 1.63.4.6.05 1.33.05 2.26.05h3.68c.93 0 1.67 0 2.26-.05a4.26 4.26 0 0 0 1.63-.4 4.15 4.15 0 0 0 1.82-1.82c.25-.48.35-1.01.4-1.63.05-.6.05-1.33.05-2.26V8.16c0-.93 0-1.67-.05-2.26a4.26 4.26 0 0 0-.4-1.63 4.15 4.15 0 0 0-1.82-1.82 4.26 4.26 0 0 0-1.63-.4C13.5 2 12.77 2 11.84 2zm-6.9 1.79c.25-.12.56-.2 1.08-.25.53-.04 1.2-.04 2.17-.04h3.62c.96 0 1.64 0 2.17.04.52.05.83.13 1.07.25.5.25.9.66 1.16 1.16.12.24.2.55.25 1.07l.02.48H3.52l.02-.48c.05-.52.13-.83.25-1.07.25-.5.66-.9 1.16-1.16zM3.5 8v3.81c0 .96 0 1.64.04 2.17.05.52.13.83.25 1.07.25.5.66.9 1.16 1.16.24.12.55.2 1.07.25.53.04 1.2.04 2.17.04h3.62c.96 0 1.64 0 2.17-.04a2.8 2.8 0 0 0 1.07-.25c.5-.25.9-.66 1.16-1.16.12-.24.2-.55.25-1.07.04-.53.04-1.2.04-2.17V8z" fill="currentColor" fill-rule="evenodd"></path></svg>
                </div>
                <a href="#articles" className="nav-link">
                  Статьи
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-item__icon">
                  <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M16 4a5.95 5.95 0 0 0-3.89 1.7l-.12.11-.12-.11A5.96 5.96 0 0 0 7.73 4 5.73 5.73 0 0 0 2 9.72c0 3.08 1.13 4.55 6.18 8.54l2.69 2.1c.66.52 1.6.52 2.26 0l2.36-1.84.94-.74c4.53-3.64 5.57-5.1 5.57-8.06A5.73 5.73 0 0 0 16.27 4zm.27 1.8a3.93 3.93 0 0 1 3.93 3.92v.3c-.08 2.15-1.07 3.33-5.51 6.84l-2.67 2.08a.04.04 0 0 1-.04 0L9.6 17.1l-.87-.7C4.6 13.1 3.8 11.98 3.8 9.73A3.93 3.93 0 0 1 7.73 5.8c1.34 0 2.51.62 3.57 1.92a.9.9 0 0 0 1.4-.01c1.04-1.3 2.2-1.91 3.57-1.91z" fill="currentColor" fill-rule="nonzero"></path></g></svg>
                </div>
                <a href="#reactions" className="nav-link">
                  Реакции
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-item__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="M9.32 1.5h1.36c.96 0 1.72 0 2.33.05.63.05 1.17.16 1.67.41a4.25 4.25 0 0 1 1.86 1.86c.25.5.36 1.04.41 1.67.05.61.05 1.38.05 2.33v6.57c0 .77 0 1.4-.04 1.9-.05.48-.14.98-.46 1.37a2.25 2.25 0 0 1-1.77.84c-.5 0-.94-.25-1.34-.52-.41-.27-.9-.68-1.5-1.17l-.85-.69c-.27-.22-.45-.37-.6-.47a.82.82 0 0 0-.24-.13.75.75 0 0 0-.4 0 .82.82 0 0 0-.24.13c-.15.1-.33.25-.6.47l-.85.7c-.6.48-1.09.89-1.5 1.16-.4.27-.84.51-1.34.52a2.25 2.25 0 0 1-1.77-.84c-.32-.4-.41-.89-.46-1.37-.04-.5-.04-1.13-.04-1.9V7.82c0-.96 0-1.72.05-2.33.05-.63.16-1.17.41-1.67a4.25 4.25 0 0 1 1.86-1.86c.5-.25 1.04-.36 1.67-.41.61-.05 1.38-.05 2.33-.05Zm-2.2 1.55c-.54.04-.87.12-1.12.25a2.75 2.75 0 0 0-1.2 1.2c-.13.25-.21.58-.25 1.11-.05.55-.05 1.25-.05 2.24v6.5c0 .82 0 1.39.04 1.8.04.43.1.54.13.57a.75.75 0 0 0 .59.28c.03 0 .16-.02.51-.26.35-.23.79-.6 1.42-1.11l.84-.68c.25-.21.47-.4.67-.53.2-.15.43-.27.7-.35a2.25 2.25 0 0 1 1.2 0c.27.08.5.2.7.35.2.14.42.32.67.53l.84.68c.63.52 1.07.88 1.42 1.1.35.25.48.27.51.27a.75.75 0 0 0 .6-.28c.01-.03.08-.14.12-.57.04-.41.04-.98.04-1.8v-6.5c0-1 0-1.7-.05-2.24a2.91 2.91 0 0 0-.25-1.1A2.75 2.75 0 0 0 14 3.3a2.91 2.91 0 0 0-1.11-.25C12.34 3 11.64 3 10.65 3h-1.3c-1 0-1.7 0-2.24.05Z" clip-rule="evenodd"></path></svg>
                </div>
                <a href="#favorites" className="nav-link">
                  Избранное
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-item__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M18.57 6.91a.82.82 0 0 0-.58.1h-.02L14.2 8.9H7.6c-.77 0-1.45.29-1.94.73a2.7 2.7 0 0 0-.76 1.87c0 .7.32 1.46.76 1.87a2.76 2.76 0 0 0 1.94.72h6.61l3.76 1.88.02.02a.81.81 0 0 0 .41.1c.22 0 .4-.08.52-.2a.5.5 0 0 0 .18-.36V7.46a.48.48 0 0 0-.09-.27.7.7 0 0 0-.44-.28ZM17.1 5.44a2.62 2.62 0 0 1 1.82-.29 2.5 2.5 0 0 1 1.56 1.01c.26.38.41.84.41 1.3v8.06c0 .68-.3 1.27-.76 1.7a2.57 2.57 0 0 1-1.74.66c-.45 0-.9-.12-1.28-.34l-3.33-1.66H7.6a4.91 4.91 0 0 1-.98-.1 4.6 4.6 0 0 1-2.17-1.1A4.5 4.5 0 0 1 3.1 11.5c0-1.13.48-2.4 1.35-3.2A4.66 4.66 0 0 1 7.6 7.1h6.19l3.33-1.65Z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M14.9 8v6.98h-1.8V8h1.8Z" clip-rule="evenodd"></path><path d="m8.78 17.62-.31-1.74H7.6c-.33 0-.66-.03-.98-.1L7 17.93c.12.66.5 1.21 1.02 1.55a2.5 2.5 0 0 0 1.83.38 2.5 2.5 0 0 0 1.58-.98c.37-.5.55-1.14.43-1.8l-.2-1.2H9.81l.27 1.5a.56.56 0 0 1-.11.44.7.7 0 0 1-.45.27.7.7 0 0 1-.5-.1.56.56 0 0 1-.26-.37Z"></path></svg>
                </div>
                <a href="#advertising" className="nav-link">
                  Реклама
                </a>
              </li>
            </ul>
          </div>
        </div>
      </container>
    </>
  )
}
